import React, { useContext, useState } from "react";
import styles from "./FirstBlock.module.scss";
import RightMenu from "../RightMenu/RightMenu";
import avalancheBuild from "src/modules/mainPage/assets/img/blockchainBuilds/avalancheBuild.png";
import bscBuild from "src/modules/mainPage/assets/img/blockchainBuilds/bscBuild.png";
import coreBuild from "src/modules/mainPage/assets/img/blockchainBuilds/coreBuild.png";
import skaleBuild from "src/modules/mainPage/assets/img/blockchainBuilds/skaleBuild.png";
import neoBuild from "src/modules/mainPage/assets/img/blockchainBuilds/neoBuild.png";
import { Context } from "src";
import { observer } from "mobx-react-lite";

export const array = {
	bsc: bscBuild,
	core: coreBuild,
	neo: neoBuild,
	avalanche: avalancheBuild,
	skale: skaleBuild,
};

const FirstBlock = observer(() => {
	const { storeBlockchains } = useContext(Context);

	return (
		<div className={styles.content}>
			<div className={styles.title} data-text={"explore  pixudi  multiverse"}>
				explore pixudi multiverse
			</div>
			<div className={styles.wrap}>
				<div></div>
				<img
					src={array[storeBlockchains.blockchain]}
					alt={""}
					className={styles.building}
				/>
				<div className={styles.rightMenu}>
					<RightMenu />
				</div>
			</div>
		</div>
	);
});

export default FirstBlock;
