import { makeAutoObservable, runInAction } from "mobx";
import { tBlockchains } from "../types/blockchains";

export default class StoreBlockchains {
	blockchain: tBlockchains = "bsc";

	constructor() {
		makeAutoObservable(this);
	}

	setBlockchain = (blockchain: tBlockchains) => {
		runInAction(() => {
			this.blockchain = blockchain;
		});
	};
}
