import React from "react";
import styles from "./LastBlock.module.scss";

const LastBlock = () => {
	return (
		<div className={styles.content}>
			<div className={styles.title} data-text={"more info coming soon"}>
				more info coming soon
			</div>
			<svg height="1" fill="none" xmlns="http://www.w3.org/2000/svg">
				<line opacity="0.3" y1="0.5" x2="100000" y2="0.5" stroke="white" />
			</svg>
		</div>
	);
};

export default LastBlock;
