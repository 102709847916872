import { useContext, useEffect, useState } from "react";
import styles from "./BlasterBlock.module.scss";
import handleAvax from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Avalanche/1.png";
import fuelTankAvax from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Avalanche/2.png";
import triggerAvax from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Avalanche/3.png";
import reactorAvax from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Avalanche/4.png";
import radiatorAvax from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Avalanche/5.png";
import muzzleAvax from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Avalanche/6.png";
import handleBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/BNB/1.png";
import fuelTankBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/BNB/2.png";
import triggerBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/BNB/3.png";
import reactorBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/BNB/4.png";
import radiatorBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/BNB/5.png";
import muzzleBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/BNB/6.png";
import handleCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Core/1.png";
import fuelTankCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Core/2.png";
import triggerCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Core/3.png";
import reactorCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Core/4.png";
import radiatorCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Core/5.png";
import muzzleCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Core/6.png";
import handleNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Neo/1.png";
import fuelTankNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Neo/2.png";
import triggerNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Neo/3.png";
import reactorNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Neo/4.png";
import radiatorNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Neo/5.png";
import muzzleNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Neo/6.png";
import handleSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Skale/1.png";
import fuelTankSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Skale/2.png";
import triggerSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Skale/3.png";
import reactorSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Skale/4.png";
import radiatorSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Skale/5.png";
import muzzleSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Skale/6.png";
import handleActiveAvalanche from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Avalanche/1.png";
import fuelTankActiveAvalanche from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Avalanche/2.png";
import triggerActiveAvalanche from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Avalanche/3.png";
import reactorActiveAvalanche from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Avalanche/4.png";
import radiatorActiveAvalanche from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Avalanche/5.png";
import muzzleActiveAvalanche from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Avalanche/6.png";
import handleActiveBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/BNB/1.png";
import fuelTankActiveBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/BNB/2.png";
import triggerActiveBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/BNB/3.png";
import reactorActiveBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/BNB/4.png";
import radiatorActiveBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/BNB/5.png";
import muzzleActiveBnb from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/BNB/6.png";
import handleActiveCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Core/1.png";
import fuelTankActiveCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Core/2.png";
import triggerActiveCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Core/3.png";
import reactorActiveCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Core/4.png";
import radiatorActiveCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Core/5.png";
import muzzleActiveCore from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Core/6.png";
import handleActiveNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Neo/1.png";
import fuelTankActiveNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Neo/2.png";
import triggerActiveNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Neo/3.png";
import reactorActiveNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Neo/4.png";
import radiatorActiveNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Neo/5.png";
import muzzleActiveNeo from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Neo/6.png";
import handleActiveSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Skale/1.png";
import fuelTankActiveSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Skale/2.png";
import triggerActiveSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Skale/3.png";
import reactorActiveSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Skale/4.png";
import radiatorActiveSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Skale/5.png";
import muzzleActiveSkale from "src/modules/mainPage/assets/img/blasterBlock/blasterElements/Active/Skale/6.png";
import ButtonGetBlaster from "../ButtonGetBlaster/ButtonGetBlaster";
import cl from "classnames";
import { observer } from "mobx-react-lite";
import { Context } from "src";
import { useWindowSize } from "@uidotdev/usehooks";

export const BlasterBlock = observer(() => {
	const [selectedElement, setSelectedElement] = useState<
		| "muzzle"
		| "radiator"
		| "reactor"
		| "trigger"
		| "fuelTank"
		| "handle"
		| undefined
	>("muzzle");
	const { storeBlockchains } = useContext(Context);
	const windowSize = useWindowSize();

	const elements = {
		bsc: {
			handle: handleBnb,
			fuelTank: fuelTankBnb,
			trigger: triggerBnb,
			reactor: reactorBnb,
			radiator: radiatorBnb,
			muzzle: muzzleBnb,
			color: "#FFCB4A",
			shadow:
				"0 0 30px #f3ba2c, 0 0 30px #f3ba2c, 0 0 30px #f3ba2c, 0 0 58px #f3ba2c",
		},
		core: {
			handle: handleCore,
			fuelTank: fuelTankCore,
			trigger: triggerCore,
			reactor: reactorCore,
			radiator: radiatorCore,
			muzzle: muzzleCore,
			color: "#FFA841",
			shadow:
				"0 0 30px #ff9211, 0 0 30px #ff9211, 0 0 30px #ff9211, 0 0 58px #ff9211",
		},
		neo: {
			handle: handleNeo,
			fuelTank: fuelTankNeo,
			trigger: triggerNeo,
			reactor: reactorNeo,
			radiator: radiatorNeo,
			muzzle: muzzleNeo,
			color: "#00E599",
			shadow:
				"0 0 30px #00e599, 0 0 30px #00e599, 0 0 30px #00e599, 0 0 58px #00e599",
		},
		avalanche: {
			handle: handleAvax,
			fuelTank: fuelTankAvax,
			trigger: triggerAvax,
			reactor: reactorAvax,
			radiator: radiatorAvax,
			muzzle: muzzleAvax,
			color: "#FF8A9F",
			shadow:
				"0 0 30px #f14f6c, 0 0 30px #f14f6c, 0 0 30px #f14f6c, 0 0 58px #f14f6c",
		},
		skale: {
			handle: handleSkale,
			fuelTank: fuelTankSkale,
			trigger: triggerSkale,
			reactor: reactorSkale,
			radiator: radiatorSkale,
			muzzle: muzzleSkale,
			color: "#AB95FF",
			shadow:
				"0 0 30px #8f73ff, 0 0 30px #8f73ff, 0 0 30px #8f73ff, 0 0 58px #8f73ff",
		},
	};

	const elementsActive = {
		bsc: {
			handle: handleActiveBnb,
			fuelTank: fuelTankActiveBnb,
			trigger: triggerActiveBnb,
			reactor: reactorActiveBnb,
			radiator: radiatorActiveBnb,
			muzzle: muzzleActiveBnb,
		},
		core: {
			handle: handleActiveCore,
			fuelTank: fuelTankActiveCore,
			trigger: triggerActiveCore,
			reactor: reactorActiveCore,
			radiator: radiatorActiveCore,
			muzzle: muzzleActiveCore,
		},
		neo: {
			handle: handleActiveNeo,
			fuelTank: fuelTankActiveNeo,
			trigger: triggerActiveNeo,
			reactor: reactorActiveNeo,
			radiator: radiatorActiveNeo,
			muzzle: muzzleActiveNeo,
		},
		avalanche: {
			handle: handleActiveAvalanche,
			fuelTank: fuelTankActiveAvalanche,
			trigger: triggerActiveAvalanche,
			reactor: reactorActiveAvalanche,
			radiator: radiatorActiveAvalanche,
			muzzle: muzzleActiveAvalanche,
		},
		skale: {
			handle: handleActiveSkale,
			fuelTank: fuelTankActiveSkale,
			trigger: triggerActiveSkale,
			reactor: reactorActiveSkale,
			radiator: radiatorActiveSkale,
			muzzle: muzzleActiveSkale,
		},
	};

	return (
		<div className={styles.content}>
			<div
				className={styles.title}
				style={{ textShadow: elements[storeBlockchains.blockchain].shadow }}
			>
				get your unique blaster
			</div>
			<div className={styles.subTitle}>
				Navigate your mouse or touchpad to explore different parts of the
				blaster
			</div>
			<div className={styles.wrap}>
				<div className={styles.blaster}>
					{selectedElement === "handle" &&
						elementsActive[storeBlockchains.blockchain].handle !== "" && (
							<img
								src={elementsActive[storeBlockchains.blockchain].handle}
								alt=""
								className={styles.active}
							/>
						)}
					<img src={elements[storeBlockchains.blockchain].handle} alt="" />
					<div
						className={styles.handleBlock}
						onClick={() => setSelectedElement("handle")}
						onMouseEnter={() => setSelectedElement("handle")}
						onMouseLeave={() =>
							Number(windowSize.width) > 576
								? setSelectedElement(undefined)
								: {}
						}
					></div>
					{selectedElement === "fuelTank" &&
						elementsActive[storeBlockchains.blockchain].fuelTank !== "" && (
							<img
								src={elementsActive[storeBlockchains.blockchain].fuelTank}
								alt=""
								className={styles.active}
							/>
						)}
					<img src={elements[storeBlockchains.blockchain].fuelTank} alt="" />
					<div
						className={styles.fuelTankBlock}
						onClick={() => setSelectedElement("fuelTank")}
						onMouseEnter={() => setSelectedElement("fuelTank")}
						onMouseLeave={() =>
							Number(windowSize.width) > 576
								? setSelectedElement(undefined)
								: {}
						}
					></div>
					{selectedElement === "trigger" &&
						elementsActive[storeBlockchains.blockchain].trigger !== "" && (
							<img
								src={elementsActive[storeBlockchains.blockchain].trigger}
								alt=""
								className={styles.active}
							/>
						)}
					<img src={elements[storeBlockchains.blockchain].trigger} alt="" />
					<div
						className={styles.triggerBlock}
						onClick={() => setSelectedElement("trigger")}
						onMouseEnter={() => setSelectedElement("trigger")}
						onMouseLeave={() =>
							Number(windowSize.width) > 576
								? setSelectedElement(undefined)
								: {}
						}
					></div>
					{selectedElement === "reactor" &&
						elementsActive[storeBlockchains.blockchain].reactor !== "" && (
							<img
								src={elementsActive[storeBlockchains.blockchain].reactor}
								alt=""
								className={styles.active}
							/>
						)}
					<img src={elements[storeBlockchains.blockchain].reactor} alt="" />
					<div
						className={styles.reactorBlock}
						onClick={() => setSelectedElement("reactor")}
						onMouseEnter={() => setSelectedElement("reactor")}
						onMouseLeave={() =>
							Number(windowSize.width) > 576
								? setSelectedElement(undefined)
								: {}
						}
					></div>
					{selectedElement === "radiator" &&
						elementsActive[storeBlockchains.blockchain].radiator !== "" && (
							<img
								src={elementsActive[storeBlockchains.blockchain].radiator}
								alt=""
								className={styles.active}
							/>
						)}
					<img src={elements[storeBlockchains.blockchain].radiator} alt="" />
					<div
						className={styles.radiatorBlock}
						onClick={() => setSelectedElement("radiator")}
						onMouseEnter={() => setSelectedElement("radiator")}
						onMouseLeave={() =>
							Number(windowSize.width) > 576
								? setSelectedElement(undefined)
								: {}
						}
					></div>
					{selectedElement === "muzzle" &&
						elementsActive[storeBlockchains.blockchain].muzzle != "" && (
							<img
								src={elementsActive[storeBlockchains.blockchain].muzzle}
								alt=""
								className={styles.active}
							/>
						)}
					<img src={elements[storeBlockchains.blockchain].muzzle} alt="" />
					<div
						className={styles.muzzleBlock}
						onClick={() => setSelectedElement("muzzle")}
						onMouseEnter={() => setSelectedElement("muzzle")}
						onMouseLeave={() =>
							Number(windowSize.width) > 576
								? setSelectedElement(undefined)
								: {}
						}
					></div>
				</div>
				{selectedElement !== undefined && (
					<div
						className={cl(styles.selectedElement, {
							[styles.handle]: selectedElement === "handle",
							[styles.fuelTank]: selectedElement === "fuelTank",
							[styles.trigger]: selectedElement === "trigger",
							[styles.reactor]: selectedElement === "reactor",
							[styles.radiator]: selectedElement === "radiator",
							[styles.muzzle]: selectedElement === "muzzle",
						})}
					>
						<div
							className={styles.selectedElementTitle}
							style={{ color: elements[storeBlockchains.blockchain].color }}
						>
							{selectedElement === "handle" && "handle"}
							{selectedElement === "fuelTank" && "fuel tank"}
							{selectedElement === "trigger" && "trigger"}
							{selectedElement === "reactor" && "reactor"}
							{selectedElement === "radiator" && "radiator"}
							{selectedElement === "muzzle" && "muzzle"}
						</div>
						<div className={styles.selectedElementText}>
							{selectedElement === "handle" &&
								"The Handle provides the primary interface between the user and the portal blaster. Ergonomic design ensures comfort during use, and the material used affects the grip and durability. The grip characteristic enhances control, while advanced design options can further improve user experience."}
							{selectedElement === "fuelTank" &&
								"The Fuel Tank is the reservoir that stores the energy source for the portal blaster. Its capacity determines the duration of sustained use, while its material affects durability and weight. Efficiency ratings indicate how effectively the fuel is utilized, and weight influences the overall portability of the blaster."}
							{selectedElement === "trigger" &&
								"The Trigger is the mechanism that activates the portal blaster. Sensitivity levels affect the ease of firing, with higher sensitivity allowing for quicker response times. The material of the trigger impacts its durability and feel. Response time is critical for ensuring immediate action upon activation, and durability ensures the trigger can withstand repeated use without degradation."}
							{selectedElement === "reactor" &&
								"The Reactor is the core power source of the portal blaster, converting stored fuel into usable energy. Power output dictates the blaster’s strength. The material of the reactor affects its efficiency and stability, with higher-end materials providing better performance and reliability. Stability ensures consistent energy output, while efficiency determines how well the reactor uses the available fuel."}
							{selectedElement === "radiator" &&
								"The Radiator is crucial for managing the heat generated by the portal blaster during use. Its cooling rate ensures the blaster remains operational without overheating. The material contributes to the radiator’s efficiency and lifespan, while durability indicates how well it can withstand prolonged use. Size can influence both cooling capacity and the overall bulk of the blaster."}
							{selectedElement === "muzzle" &&
								"The Muzzle directs the discharge of energy from the portal blaster. Its shape can influence the focus and spread of the blast. Material choice impacts the muzzle’s durability and resistance to wear. Precision levels determine the accuracy of the blasts, and the length of the muzzle can affect both range and stability."}
						</div>
					</div>
				)}
				<ButtonGetBlaster />
			</div>
		</div>
	);
});
