import React from "react";
import styles from "./Header.module.scss";
import logoPixudi from "src/modules/mainPage/assets/img/header/logo.png";
import ButtonStartNow from "../ButtonStartNow/ButtonStartNow";
import burgerMenu from "src/modules/mainPage/assets/img/header/burgerMenu.svg";

const Header = () => {
	return (
		<header className={styles.content}>
			<img src={logoPixudi} alt={""} className={styles.logo} />
			<img src={burgerMenu} alt={""} className={styles.burgerMenu} />
			<div className={styles.wButton}>
				<ButtonStartNow />
			</div>
		</header>
	);
};

export default Header;
