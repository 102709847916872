import React from "react";
import styles from "./WhatIsComing.module.scss";
import cl from "classnames";

const WhatIsComing = () => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>what is coming</div>
			<div className={styles.areaBlocks}>
				<div className={styles.block}>
					<div className={styles.titleBlock}>Exclusive Collections</div>
					<div className={styles.desc}>
						Launching unique collections of 5,000 elements on premier blockchain
						marketplaces.
					</div>
				</div>
				<div className={styles.block}>
					<div className={styles.titleBlock}>Staged Releases</div>
					<div className={styles.desc}>
						The release schedule for each blockchain collection depends on your
						engagement—your activity dictates the launch order!
					</div>
				</div>
				<div className={styles.block}>
					<div className={styles.titleBlock}>Quests and Rewards</div>
					<div className={styles.desc}>
						Engage in quests to win an NFT portal gun or secure early access to
						the collection rally—get a jumpstart in our digital universe
					</div>
				</div>
				<div className={styles.block}>
					<div className={cl(styles.titleBlock, styles.titleBlockSpec)}>
						Partnership Announcements
					</div>
					<div className={cl(styles.desc, styles.descSpec)}>
						Over the next 2,5 weeks, we’ll announce new partnerships with top
						gaming guilds and key players in the Pixudi world.
					</div>
				</div>

				<div className={styles.block}>
					<div className={cl(styles.titleBlock, styles.titleBlockSpec)}>
						Appreciation for Early Users
					</div>
					<div className={cl(styles.desc, styles.descSpec)}>
						We’re honoring our early adopters by giving them special privileges
						in the upcoming launch, based on their leaderboard standings.
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhatIsComing;
