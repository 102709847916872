import React, { useEffect, useRef, useState } from "react";
import styles from "./SecondBlock.module.scss";
import arrow from "src/modules/mainPage/assets/img/seconScreen/arrow.svg";
import portal from "src/modules/mainPage/assets/img/seconScreen/portal.png";
import lock from "src/modules/mainPage/assets/img/seconScreen/lock.svg";
import { useWindowSize } from "@uidotdev/usehooks";

const SecondBlock = () => {
	const containerPortal = useRef<HTMLImageElement>(null);
	const containerScroll = useRef<HTMLDivElement>(null);
	const containerWrap = useRef<HTMLDivElement>(null);
	const containerHiddenBlock = useRef<HTMLDivElement>(null);

	const windowSize = useWindowSize();

	const [scroll, setScroll] = useState<number>(0);

	useEffect(() => {
		const handleScroll = () => {
			setScroll(window.scrollY);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if (scroll > 350 && scroll < 600 && containerScroll.current) {
			console.log("focus");

			containerScroll.current.scroll({ top: scroll / 1.2 });
			containerScroll.current.focus();
			containerScroll.current.click();

			containerScroll.current.onclick = () => {
				console.log("click");
			};
		}
	}, [scroll]);

	useEffect(() => {
		let size = windowSize.width! < 768 ? 170 : 200;
		let opacity = 0;

		if (
			containerPortal.current &&
			containerScroll.current &&
			containerWrap.current &&
			containerHiddenBlock.current
		) {
			containerScroll.current.onscroll = (event) => {
				const maxSize = windowSize.width! < 768 ? 700 : 900;

				size += 10;

				if (size > 600 && size < maxSize) {
					//opacity = 0.5;
				}

				if (size >= maxSize) {
					size = maxSize;
					opacity = 1;
					containerWrap.current!.style.height = `${
						containerScroll.current!.clientHeight
					}px`;
				} else {
					window.scrollTo({
						top: window.scrollY + 2,
					});
				}

				console.log("hello", size);

				containerPortal.current!.style.width = `${size}px`;
				containerPortal.current!.style.rotate = `${size}deg`;
				containerHiddenBlock.current!.style.opacity = `${opacity}`;
			};
		}
	}, [
		containerPortal.current,
		containerScroll.current,
		containerHiddenBlock.current,
	]);

	return (
		<div className={styles.wContent}>
			<img src={arrow} alt={""} className={styles.arrow} />
			<img
				src={portal}
				alt={""}
				ref={containerPortal}
				className={styles.portal}
			/>

			<div
				className={styles.content}
				ref={containerScroll}
				id={"containerScrollAnim"}
			>
				<div className={styles.hiddenBlock} ref={containerHiddenBlock}>
					<div className={styles.title}>how to participate</div>
					<div className={styles.areaDesc}>
						<div className={styles.unlockBlock}>
							<div className={styles.titleBlock}>available now</div>
							<div className={styles.descBlock}>
								Complete quest on DeQuest{" "}
								<span
									onClick={() => {
										window.open("https://beta.dequest.io/realms/pixudi");
									}}
								>
									here
								</span>
							</div>
						</div>
						<div className={styles.lockBlock}>
							<img src={lock} alt={""} />
						</div>
						<div className={styles.lockBlock}>
							<img src={lock} alt={""} />
						</div>
						<div className={styles.lockBlock}>
							<img src={lock} alt={""} />
						</div>
					</div>
				</div>
				<div className={styles.wrap} ref={containerWrap}></div>
			</div>
		</div>
	);
};

export default SecondBlock;
