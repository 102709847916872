import React, { useEffect, useRef } from "react";
import styles from "./BlockWithPortal.module.scss";
import portalSpritelist from "src/modules/mainPage/assets/img/portal/portalSpritelist.png";
import { jSprite } from "src/modules/animation/JSprite.js";
import pinkShadow from "src/modules/mainPage/assets/img/portal/shadows/pinkShadow.svg";
import purpleShadow from "src/modules/mainPage/assets/img/portal/shadows/purpleShadow.svg";
import violetShadow from "src/modules/mainPage/assets/img/portal/shadows/violetShadow.svg";

const BlockWithPortal = () => {
	const containerPortal = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerPortal.current) {
			const initAnimation = async () => {
				{
					let el = document.createElement("div");
					el.style.willChange = "background-position, left";
					el.setAttribute("id", "portalSpritelist");
					new jSprite({
						spriteSheet: portalSpritelist,
						container: "portalSpritelist",
						columns: 36,
						rows: 1,
						startFrame: 1,
						timing: 90,
						autoStart: true,
						repeat: true,
					});
					containerPortal.current?.appendChild(el);
				}
			};

			initAnimation();
		}
	}, [containerPortal.current]);

	return (
		<div className={styles.content}>
			<div className={styles.block}>
				<div className={styles.mask}>
					<img src={pinkShadow} alt={""} className={styles.pinkShadow} />
					<img src={purpleShadow} alt={""} className={styles.purpleShadow} />
					<img src={violetShadow} alt={""} className={styles.violetShadow} />
				</div>

				<div className={styles.leftSide}>
					<div className={styles.title}>WHAT IS PIXUDI MULTIVERSE</div>
					<div className={styles.description}>
						This is a set of NFT collections - universes - dedicated to the most
						popular blockchains integrated in Pixudi game: Neo, Avalanche, BNB,
						CORE, SKALE. Ownership of each type of NFT will grant you special
						benefits in the future, including eligibility for{" "}
						<span>token airdrop</span>
					</div>
				</div>
				<div>
					<div className={styles.portal} ref={containerPortal}></div>
				</div>
			</div>
		</div>
	);
};

export default BlockWithPortal;
