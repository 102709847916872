import React, { useContext } from "react";
import styles from "./RightMenu.module.scss";
import avalancheIcon from "src/modules/mainPage/assets/img/righMenu/avalancheIcon.png";
import bscIcon from "src/modules/mainPage/assets/img/righMenu/bscIcon.svg";
import coreIcon from "src/modules/mainPage/assets/img/righMenu/coreIcon.png";
import neoIcon from "src/modules/mainPage/assets/img/righMenu/neoIcon.svg";
import skaleIcon from "src/modules/mainPage/assets/img/righMenu/skaleIcon.png";
import cl from "classnames";
import { observer } from "mobx-react-lite";
import { Context } from "src";

const RightMenu: React.FC = observer(() => {
	const { storeBlockchains } = useContext(Context);

	return (
		<div className={styles.content}>
			<div className={styles.wTitle}>
				<div className={styles.title}>Choose your universe</div>
			</div>
			<div className={styles.blockchains}>
				<img
					src={avalancheIcon}
					alt={""}
					onClick={() => storeBlockchains.setBlockchain("avalanche")}
					className={cl(styles.iconOfBlockchain, {
						[styles.active]: storeBlockchains.blockchain === "avalanche",
					})}
				/>
				<img
					src={coreIcon}
					alt={""}
					onClick={() => storeBlockchains.setBlockchain("core")}
					className={cl(styles.iconOfBlockchain, {
						[styles.active]: storeBlockchains.blockchain === "core",
					})}
				/>
				<img
					src={neoIcon}
					alt={""}
					onClick={() => storeBlockchains.setBlockchain("neo")}
					className={cl(styles.iconOfBlockchain, {
						[styles.active]: storeBlockchains.blockchain === "neo",
					})}
				/>
				<img
					src={bscIcon}
					alt={""}
					onClick={() => storeBlockchains.setBlockchain("bsc")}
					className={cl(styles.iconOfBlockchain, {
						[styles.active]: storeBlockchains.blockchain === "bsc",
					})}
				/>
				<img
					src={skaleIcon}
					alt={""}
					onClick={() => storeBlockchains.setBlockchain("skale")}
					className={cl(styles.iconOfBlockchain, {
						[styles.active]: storeBlockchains.blockchain === "skale",
					})}
				/>
			</div>
		</div>
	);
});

export default RightMenu;
