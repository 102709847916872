import React from "react";
import styles from "./ButtonGetBlaster.module.scss";
import cl from "classnames";

const ButtonGetBlaster: React.FC = () => {
	return (
		<div
			className={cl(styles.wBtn)}
			onClick={() => {
				window.open("https://beta.dequest.io/realms/pixudi");
			}}
		>
			<svg
				className={styles.btn}
				width="206"
				height="44"
				viewBox="0 0 206 44"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 19.2455C1 15.9956 2.96611 13.0686 5.97472 11.8396L31.0558 1.59408C32.0162 1.20177 33.0437 1 34.0811 1H202C203.657 1 205 2.34314 205 4V26.4781C205 29.3864 203.202 31.9918 200.482 33.0231L175.376 42.5451C174.583 42.8459 173.742 43 172.893 43H4C2.34315 43 1 41.6569 1 40V19.2455Z"
					fill="#D9D9D9"
					fillOpacity="0.01"
					stroke="#4FD4F1"
					strokeWidth="2"
				/>
			</svg>
			<div className={styles.text}>TBA</div>
		</div>
	);
};

export default ButtonGetBlaster;
