import React from "react";
import styles from "./MainFeatures.module.scss";
import dimond from "src/modules/mainPage/assets/img/mainFeatures/dimond.svg";
import boards from "src/modules/mainPage/assets/img/mainFeatures/boards.svg";
import passes from "src/modules/mainPage/assets/img/mainFeatures/passes.svg";
import ButtonStartNow from "../ButtonStartNow/ButtonStartNow";
import shadowBoards from "src/modules/mainPage/assets/img/mainFeatures/shadows/shadowBoards.svg";
import shadowPasses from "src/modules/mainPage/assets/img/mainFeatures/shadows/shadowPasses.svg";
import shadowDimond from "src/modules/mainPage/assets/img/mainFeatures/shadows/shadowDimond.svg";

const MainFeatures = () => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>MAIN FEATURES</div>
			<div className={styles.areaBlocks}>
				<div className={styles.block}>
					<div className={styles.shadow}>
						<img src={shadowBoards} alt={""} />
					</div>
					<div className={styles.iconBlock}>
						<img src={boards} alt={""} className={styles.board} />
					</div>
					<div className={styles.titleBoard}>generative boards</div>
					<div className={styles.description}>
						Tired of playing on the same boards? How about playing on a unique,
						newly generated board each time? Choose the season you want and the
						game map will be randomly generated from a set of elements.
					</div>
				</div>
				<div className={styles.block}>
					<div className={styles.shadow}>
						<img src={shadowDimond} alt={""} />
					</div>
					<div className={styles.iconBlock}>
						<img src={dimond} alt={""} className={styles.diamond} />
					</div>
					<div className={styles.titleDiamond}>blockchain-related</div>
					<div className={styles.description}>
						Each blockchain will have its own unique NFT collection on
						launchpads. And - guess what - each blockchain will have a unique
						region on the map that can only be accessed using its network!
					</div>
				</div>
				<div className={styles.block}>
					<div className={styles.shadow}>
						<img src={shadowPasses} alt={""} />
					</div>
					<div className={styles.iconBlock}>
						<img src={passes} alt={""} className={styles.passes} />
					</div>
					<div className={styles.titlePasses}>NFT PASSES</div>
					<div className={styles.description}>
						Purchase your NFT pass to get a unique opportunity to swap your NFTs
						that have no use to valuable items in Pixudi world! We love
						recycling trash, so why not do it in WEB3 world?
					</div>
				</div>
			</div>
			<ButtonStartNow />
		</div>
	);
};

export default MainFeatures;
