import React, { useEffect } from "react";
import styles from "./MainPage.module.scss";
import Header from "../Header/Header";
import FirstBlock from "../FirstBlock/FirstBlock";
import SecondBlock from "../SecondBlock/SecondBlock";
import MainFeatures from "../MainFeatures/MainFeatures";
import BlockWithPortal from "../BlockWithPortal/BlockWithPortal";
import WhatIsComing from "../WhatIsComing/WhatIsComing";
import LastBlock from "../LastBlock/LastBlock";
import { BlasterBlock } from "../BlasterBlock/BlasterBlock";

const MainPage = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<div className={styles.content}>
			<Header />
			<FirstBlock />
			<SecondBlock />
			<MainFeatures />
			<BlasterBlock />
			<BlockWithPortal />
			<WhatIsComing />
			<LastBlock />
		</div>
	);
};

export default MainPage;
