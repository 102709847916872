import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import "./app/styles/index.scss";
import StoreBlockchains from "./modules/mainPage/store/storeBlockchains";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const storeBlockchains = new StoreBlockchains();

interface State {
	storeBlockchains: StoreBlockchains;
}

export const Context = createContext<State>({
	storeBlockchains,
});

root.render(
	<React.StrictMode>
		<Context.Provider
			value={{
				storeBlockchains,
			}}
		>
			<App />
		</Context.Provider>
	</React.StrictMode>
);
