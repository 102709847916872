import React from "react";
import styles from "./ButtonStartNow.module.scss";

const ButtonStartNow = () => {
	return (
		<div className={styles.wBtn} onClick={()=>{
			window.open("https://beta.dequest.io/realms/pixudi");
		}}>
			<svg
				className={styles.btn}
				width="153"
				height="44"
				viewBox="0 0 153 44"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 18.5214C1 15.6039 2.58826 12.9177 5.14465 11.5117L22.4564 1.99027C23.6375 1.34063 24.9637 1 26.3117 1H149C150.657 1 152 2.34315 152 4V27.1025C152 29.7325 150.526 32.1406 148.183 33.3367L130.759 42.2342C129.773 42.7376 128.682 43 127.576 43H4C2.34314 43 1 41.6569 1 40V18.5214Z"
					stroke="#4FD4F1"
					strokeWidth="2"
				/>
			</svg>
			<div className={styles.text}>Start Now</div>
		</div>
	);
};

export default ButtonStartNow;
